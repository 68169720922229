<template>
    <crefo-default-footer />
</template>

<script lang="ts" setup>
    import CrefoDefaultFooter from './components/CrefoDefaultFooter.vue'
    import { allowedLocales, AllowedLocalesTypes, changeLocale } from '@/i18n'
    import { onMounted, onBeforeUnmount } from "vue"

    const logPackageName = () => {
        /* istanbul ignore next -- @preserve */
        if (import.meta.env.MODE !== 'test') {
            console.log(`SCS ${__APP_NAME__} ${__APP_VERSION__}`)
        }
    }

    logPackageName()

    onMounted(() => {
        window.addEventListener('languageChanged', setLanguage, false)
        setLanguage()
    })

    onBeforeUnmount(() => {
        window.removeEventListener('languageChanged', setLanguage)
    })

    function setLanguage () {
        let clientLanguage: AllowedLocalesTypes = 'de_DE'

        if (document.cookie.indexOf('CAS_PREFERRED_LANGUAGE') !== -1) {
            let langCode = document.cookie.split('CAS_PREFERRED_LANGUAGE=')[1].split(';')[0]
            if (langCode && langCode.length && allowedLocales.includes(langCode as AllowedLocalesTypes)) {
                clientLanguage = langCode as AllowedLocalesTypes
            }
        }

        changeLocale(clientLanguage)
    }
</script>
