<template>
    <footer>
        <div class="benefits bg-brand-light-grey">
            <div class="container pt-small">
                <div class="row">
                    <div class="col-12 mb-small">
                        <ul class="benefits-list">
                            <li class="text-bold text-brand-darkest-blue">
                                {{ $t('benefits_list.item1') }}
                            </li>
                            <li
                                class="text-bold text-brand-darkest-blue"
                                data-qa="footer-years-of-experience"
                            >
                                {{ $t('benefits_list.item2', {years: getYearsOfExperience()}) }}
                            </li>
                            <li class="text-bold text-brand-darkest-blue">
                                {{ $t('benefits_list.item3') }}
                            </li>
                            <li class="text-bold text-brand-darkest-blue">
                                {{ $t('benefits_list.item4') }}
                            </li>
                            <li class="text-bold text-brand-darkest-blue">
                                {{ $t('benefits_list.item5') }}
                            </li>
                            <li class="text-bold text-brand-darkest-blue">
                                {{ $t('benefits_list.item6') }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="isAuthenticated && showContactFooter"
            class="vc-contact bg-brand-darkest-blue"
        >
            <div class="container">
                <div class="row">
                    <div class="col-12 pt">
                        <h2
                            class="vc-name text-white"
                            data-qa="contact-footer-vc-name"
                        >
                            {{ contact.vcName }}
                        </h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4 mb">
                        <template v-if="contact.phone">
                            <i
                                class="crefo-brand-icon icon-phone-arrow icon-color-white"
                                aria-hidden="true"
                            />
                            <p
                                class="text-white pt-small"
                                data-qa="contact-footer-vc-phone"
                            >
                                <template v-if="isDefaultContactPhone">
                                    {{ $t('contact.phone.technical_only') }}<br>
                                </template>
                                {{ contact.phone }}
                            </p>
                        </template>
                    </div>
                    <div class="col-12 col-md-4 mb">
                        <i
                            class="crefo-brand-icon icon-envelope icon-color-white"
                            aria-hidden="true"
                        />
                        <p class="text-white pt-small">
                            <template v-if="contact.email">
                                <a
                                    class="text-white contact-footer-email"
                                    :href="'mailto:' + contact.email"
                                    data-qa="contact-footer-vc-email"
                                    >
                                    {{ contact.email }}
                                </a><br>
                            </template>
                            <a
                                v-if="isMitgliedsbenutzer"
                                class="text-white"
                                v-bind="{ 'href': nav.contactLink.href[currentLocale] }"
                                :data-qa="nav.contactLink.dataQa"
                            >
                                <span>
                                    <i
                                        class="crefo-ui-icon icon-chevron-next contact-link-icon"
                                        aria-hidden="true"
                                    />{{ $t(nav.contactLink.translateKey) }}
                                </span>
                            </a>
                        </p>
                    </div>
                    <div class="col-12 col-md-4 mb">
                        <template v-if="contact.firstName && contact.lastName || contact.email || contact.phone">
                            <i
                                class="crefo-brand-icon icon-user-open icon-color-white"
                                aria-hidden="true"
                            />

                            <p
                                class="text-white pt-small"
                            >
                                <template v-if="contact.firstName && contact.lastName">
                                    <span data-qa="contact-footer-vc-first-name">{{ contact.firstName }}</span>
                                    <span data-qa="contact-footer-vc-last-name">&nbsp;{{ contact.lastName }}</span><br>
                                </template>
                                <template v-if="contact.email">
                                        <a
                                            class="text-white contact-footer-email"
                                            :href="'mailto:' + contact.email"
                                            data-qa="contact-footer-vc-email2"
                                        >
                                            {{ contact.email }}
                                        </a><br>
                                </template>
                                <template v-if="isDefaultContactPhone">
                                        {{ $t('contact.phone.technical_only') }}
                                    <br>
                                </template>
                                <span data-qa="contact-footer-vc-phone2">
                                    {{ contact.phone }}
                                </span>
                            </p>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-menu text-white bg-brand-darkest-grey">
            <div class="container mb">
                <div class="row pt">
                    <div
                        v-if="isAuthenticated && isMitgliedsbenutzer"
                        class="col-12 col-md-6 col-xl-3"
                        data-qa="column-1"
                    >
                        <ul class="nav-footer">
                            <li
                                v-for="(item, i) in nav.column1"
                                :key="i"
                            >
                                <a
                                    v-bind="{ 'href': item.href[currentLocale] }"
                                    :data-qa="item.dataQa"
                                >
                                    <span>{{ $t(item.translateKey) }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div
                        v-if="isAuthenticated && isMitgliedsbenutzer"
                        class="col-12 col-md-6 col-xl-3"
                        data-qa="column-2"
                    >
                        <ul class="nav-footer">
                            <li
                                v-for="(item, i) in nav.column2"
                                :key="i"
                            >
                                <a
                                    v-bind="{ 'href': item.href[currentLocale] }"
                                    :data-qa="item.dataQa"
                                >
                                    <span>{{ $t(item.translateKey) }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div
                        class="col-12 col-md-6 col-xl-3"
                        data-qa="column-3"
                    >
                        <div :class="{'offset-md-3 offset-sm-0 offset-0': isAuthenticated}">
                            <ul
                                class="nav-footer"
                            >
                                <li
                                    v-for="(item, i) in nav.column3"
                                    :key="i"
                                >
                                    <a
                                        v-bind="{ 'href': item.href[currentLocale] }"
                                        :data-qa="item.dataQa"
                                    >
                                        <span>{{ $t(item.translateKey) }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-3">
                        <h3
                            class="footer__headline"
                            data-qa="ce-header-23"
                        >
                            {{ $t('memberof') }}
                        </h3>
                        <a
                            href="http://www.credit-manager.de/"
                            class="footer__logo-link"
                            target="_blank"
                        >
                            <img
                                src="@/assets/images/footer-logo-bvcm.svg"
                                width="80"
                                height="31"
                                alt="BvCM"
                            >
                        </a>
                        <a
                            v-if="currentLocale !== 'de_AT'"
                            href="http://www.inkasso.de/"
                            class="footer__logo-link"
                            target="_blank"
                            data-qa="logo-bdiu"
                        >
                            <img
                                src="@/assets/images/footer-logo-bdiu.svg"
                                width="80"
                                height="24"
                                alt="BDIU"
                            >
                        </a>
                    </div>
                </div>
            </div>
            <div class="container copyright">
                <div class="row">
                    <div class="col-12 pt-small">
                        <p class="mb-small">
                            &copy; {{ currentYear }} Verband der Vereine Creditreform e.V.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script lang="ts" setup>
    import * as api from '../services'
    import axios from 'axios'
    import { computed, onMounted, ref } from "vue"
    import { useI18n } from "vue-i18n"

    interface ContactEvent extends Event {
        detail: {
            type: string
        }
    }

    const i18nLocale = useI18n()
    const { t } = i18nLocale

    const isAuthenticated = ref(false)
    const showContactFooter = ref(false)
    const contacts = ref<Contact[]>([])
    let contactToLoad = 'ALLGEMEIN'
    const contact = ref({
        vcName: '' as string,
        email: '' as string,
        phone: '' as string,
        firstName: '' as string,
        lastName: '' as string
    })
    const currentYear = new Date().getFullYear()

    function getYearsOfExperience () {
        const baseExperience = 142
        const yearOfBaseExperience = 2021
        const currentDate = new Date()
        const yearsElapsed = Math.max(currentDate.getFullYear() - yearOfBaseExperience, 0)
        if (currentDate.getMonth() < 7 || (currentDate.getMonth() === 7 && currentDate.getDate() < 9)) {
            return baseExperience + yearsElapsed - 1
        } else {
            return baseExperience + yearsElapsed
        }
    }

    const isMitgliedsbenutzer = computed(() => {
        return !!window.keycloak?.tokenParsed?.cr_mapping
    })

    const isDefaultContactPhone = computed(() => {
        return contact.value.phone === '+49 2131 109-300'
    })

    const currentLocale = computed(() => {
        return i18nLocale.locale.value as 'de_DE' | 'de_AT' | 'en_US' | 'fr_FR' | 'fr_LU'
    })

    const nav = ref({
        contactLink: {
            translateKey: 'menu.contact',
            dataQa: 'contact-footer-vc-contact-form',
            href: {
                de_DE: '/kontakt/',
                de_AT: '/de-at/kontakt/',
                en_US: '/en/contact/',
                fr_FR: '/fr/contact/',
                fr_LU: '/fr-lu/contact/'
            }
        },
        column1: [
            {
                translateKey: 'menu.about',
                dataQa: 'footer-link-11',
                href: {
                    de_DE: '/ueber-creditreform/',
                    de_AT: '/de-at/ueber-creditreform/',
                    en_US: '/en/about-creditreform/',
                    fr_FR: '/fr/a-propos-de-creditreform/',
                    fr_LU: '/fr-lu/a-propos-de-creditreform/'
                }
            }
        ],
        column2: [
            {
                translateKey: 'menu.order_information',
                dataQa: 'footer-link-142',
                href: {
                    de_DE: '/bonitaetsauskunft/bestellung-einer-wirtschaftsauskunft/',
                    de_AT: '/de-at/bonitaetsauskunft/bestellung-einer-wirtschaftsauskunft/',
                    en_US: '/en/business-reports/ordering-of-business-reports/',
                    fr_FR: '/fr/solvabilite/commande-dun-rapport-commercial/',
                    fr_LU: '/fr-lu/solvabilite/commande-dun-rapport-commercial/'
                }
            },
            {
                translateKey: 'menu.help',
                dataQa: 'footer-link-92',
                href: {
                    de_DE: '/hilfe/',
                    de_AT: '/de-at/hilfe/',
                    en_US: '/en/help/',
                    fr_FR: '/fr/aide/',
                    fr_LU: '/fr-lu/aide/'
                }
            },
            {
                translateKey: 'menu.contact',
                dataQa: 'footer-link-13',
                href: {
                    de_DE: '/kontakt/',
                    de_AT: '/de-at/kontakt/',
                    en_US: '/en/contact/',
                    fr_FR: '/fr/contact/',
                    fr_LU: '/fr-lu/contact/'
                }
            }
        ],
        column3: [
            {
                translateKey: 'menu.impressum',
                dataQa: 'footer-link-93',
                href: {
                    de_DE: '/impressum/',
                    de_AT: '/de-at/impressum/',
                    en_US: '/en/legal-notice/',
                    fr_FR: '/fr/impressum/',
                    fr_LU: '/fr-lu/impressum/'
                }
            },
            {
                translateKey: 'menu.data_protection',
                dataQa: 'footer-link-95',
                href: {
                    de_DE: '/datenschutz/',
                    de_AT: '/de-at/datenschutz/',
                    en_US: '/en/data-protection/',
                    fr_FR: '/fr/protection-des-donnees-aupres-de-creditreform/',
                    fr_LU: '/fr-lu/protection-des-donnees-aupres-de-creditreform/'
                }
            },
            {
                translateKey: 'menu.disclaimer',
                dataQa: 'footer-link-96',
                href: {
                    de_DE: '/nutzungshinweise/',
                    de_AT: '/de-at/nutzungshinweise/',
                    en_US: '/en/disclaimer/',
                    fr_FR: '/fr/instructions-dutilisation/',
                    fr_LU: '/fr-lu/instructions-dutilisation/'
                }
            },
            {
                translateKey: 'menu.dsgvo',
                dataQa: 'footer-link-188',
                href: {
                    de_DE: '/eu-dsgvo/',
                    de_AT: '/de-at/eu-dsgvo/',
                    en_US: '/en/eu-dsgvo/',
                    fr_FR: '/fr/eu-dsgvo/',
                    fr_LU: '/fr-lu/eu-dsgvo/'
                }
            },
            {
                translateKey: 'menu.cookie_settings',
                dataQa: 'footer-link-usercentrics',
                href: {
                    de_DE: 'javascript:UC_UI.showSecondLayer()',
                    de_AT: 'javascript:UC_UI.showSecondLayer()',
                    en_US: 'javascript:UC_UI.showSecondLayer()',
                    fr_FR: 'javascript:UC_UI.showSecondLayer()',
                    fr_LU: 'javascript:UC_UI.showSecondLayer()'
                }
            },
            {
                translateKey: 'menu.data_copy',
                dataQa: 'footer-link-dsgvo-datacopy',
                href: {
                    de_DE: 'https://www.creditreform.de/loesungen/bonitaet-risikobewertung/bonitaet-unternehmen/dsgvo-datenkopie',
                    de_AT: 'https://www.creditreform.de/loesungen/bonitaet-risikobewertung/bonitaet-unternehmen/dsgvo-datenkopie',
                    en_US: 'https://www.creditreform.de/loesungen/bonitaet-risikobewertung/bonitaet-unternehmen/dsgvo-datenkopie',
                    fr_FR: 'https://www.creditreform.de/loesungen/bonitaet-risikobewertung/bonitaet-unternehmen/dsgvo-datenkopie',
                    fr_LU: 'https://www.creditreform.de/loesungen/bonitaet-risikobewertung/bonitaet-unternehmen/dsgvo-datenkopie'
                }
            }
        ]
    })

    onMounted(() => {
        window.addEventListener('loggedIn', onLoggedIn, false)

        window.addEventListener('loggedOut', () => {
            isAuthenticated.value = false
        }, false)

        window.addEventListener('keycloakTokenUpdated', () => {
            // Update the request interceptor on keycloak token updates
            console.log('keycloakTokenUpdated')

            axios.interceptors.request.use(
                async config => {
                    if (!window?.keycloak?.token) return config

                    if (typeof window.keycloak?.tokenParsed?.exp === 'number' && typeof window.keycloak.timeSkew === 'number') {
                        let tokenExpires = Math.round(window.keycloak.tokenParsed.exp + window.keycloak.timeSkew - new Date().getTime() / 1000)
                        if (tokenExpires < 10) {
                            await window.updateKeycloakToken()
                        }
                    }
                    config.headers.Authorization = `Bearer ${window.keycloak.token}`

                    delete config.headers['X-Act-For-Member-Id']
                    if (sessionStorage.getItem('actForMemberId')) {
                        config.headers['X-Act-For-Member-Id'] = sessionStorage.getItem('actForMemberId')
                    }

                    return config
                },
                error => Promise.reject(error)
            )
        }, false)

        if (window.keycloak?.token) {
            onLoggedIn()
        }

        window.addEventListener('setContactByType', e => {
            contactToLoad = (e as ContactEvent).detail.type
            setContactByType(contactToLoad)
        }, false)
    })

    function onLoggedIn () {
        isAuthenticated.value = true

        if (localStorage.getItem('vue-token')) {
            // Add a request interceptor
            axios.interceptors.request.use(
                config => {
                    config.headers.Authorization = 'Bearer ' + localStorage.getItem('vue-token')
                    return config
                },
                error => {
                    Promise.reject(error)
                }
            )
        }

        setTimeout(() => {
            if (isMitgliedsbenutzer.value) {
                api.getVCContact()
                    .then(response => {
                        console.log("FOOTER RESPONSE", response.data)
                        if (response.data && response.data.name) {
                            contact.value.vcName = response.data.name
                            contacts.value = response.data.contacts || []
                            setContactByType(contactToLoad)
                            showContactFooter.value = true
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                contact.value = {
                    vcName: t('vc.name.default'),
                    email: 'mc@creditreform.de',
                    phone: '+49 2131 109-300',
                    firstName: '',
                    lastName: ''
                }
                showContactFooter.value = true
            }
        }, 750)
    }

    function setContactByType (contactType: string) {
        if (!contacts.value.length) {
            return
        }
        const oneContact = contacts.value.filter(contact => {
            return contact.contactCategory === contactType
        })[0]
        if (oneContact) {
            contact.value.email = oneContact.email
            contact.value.phone = oneContact.phone
            contact.value.firstName = oneContact?.firstName
            contact.value.lastName = oneContact?.lastName
        } else {
            // fallback to first found contact
            contact.value.email = contacts.value[0].email
            contact.value.phone = contacts.value[0].phone
            contact.value.firstName = contacts.value[0].firstName
            contact.value.lastName = contacts.value[0].lastName
        }
    }
</script>

<style scoped lang="less">
.footer__headline {
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    text-transform: uppercase;
    margin-top: 0;
}

.footer__logo-link {
    display: block;
    margin-bottom: 30px;
}

.nav-footer {
    padding-left: 0;
    margin-bottom: 30px;
    list-style: none;

    & li:nth-child(4) {
        margin-bottom: 20px;
    }

    & > li {
        position: relative;
        display: block;
        margin-bottom: 3px;

        & > a {
            line-height: 26px;
            position: relative;
            display: block;
            color: #fff;
            padding: 0;

            &:hover {
                color: #009ee2;
            }
        }
    }
}

footer .vc-contact {
    text-align: center;

    i {
        font-size: 42px;
    }
}

footer .footer-menu {
    .copyright {
        text-align: center;

        .col-12 {
            border-top: 1px solid #7F7F7F;
        }
    }
}

@media (max-width: 768px) {
    footer .benefits {
        display: none
    }
}

footer .benefits {
    text-align: center;

    .benefits-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: table;
        width: 100%;

        li {
            display: table-cell;
            padding: 0 2rem;
            position: relative;
            border-right: 2px solid transparent;
            font-size: 14px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                border-right: none;
            }
        }
    }
}

.contact-footer-email {
    word-wrap: break-word;
}

.contact-link-icon {
    font-size: 17px !important;
    margin-right: 2px;
    position: relative;
    top: 2px;
}
</style>
